import React from 'react';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import LMSButton from 'components/LMSButton';
import LMSPagination from 'components/LMSPagination';
import TrainingBaseTable from 'modules/hr/components/TrainingBaseTable';
import request from 'api/axios-instance';

import TrainingBaseFilter from '../../components/TrainingBaseFilter';
import { useTrainingBase } from '../../hooks/training-base';

const HrTraining = () => {
  const { isLoading, trainingBase, currentPage, lastPage, setCurrentPage } = useTrainingBase();

  const downloadPdfFile = () => {
    const link = document.createElement('a');
    link.setAttribute('download', '');
    link.setAttribute('target', '_blank');
    link.href = `${request.getUri()}v2/base-training/export`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex-1">
        <div className="w-full flex flex-row justify-between items-center mb-5">
          <h2 className="text-[32px] font-semibold" style={{ color: 'black' }}>
            База обученности
          </h2>
          <LMSButton prefix={<UploadIcon />} onClick={downloadPdfFile}>
            Скачать Excel
          </LMSButton>
        </div>

        <div className="mb-6">
          <TrainingBaseFilter />
        </div>

        {trainingBase && !isLoading && trainingBase.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <TrainingBaseTable trainingBase={trainingBase} />
            </div>
            <div className={lastPage > 1 ? 'mt-6' : ''}>
              <LMSPagination current={currentPage} lastPage={lastPage} onChange={setCurrentPage} />
            </div>
          </>
        )}

        {trainingBase.length === 0 && !isLoading && (
          <h3
            className="mb-10 text-[24px] font-semibold text-center mt-10"
            style={{ color: 'black' }}
          >
            Нет данных <br /> Попробуйте изменить фильтрацию
          </h3>
        )}
      </div>
    </div>
  );
};

export default HrTraining;
