import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';

export const CoachLibraryList = () => {
  return (
    <div className="pt-6">
      <div className="block lg:flex items-start ">
        <div className="flex-1">
          <div className="w-full flex flex-row justify-between lg:items-center mb-6 lg:mb-10">
            <h2 className="text-[32px] font-semibold">Библиотека</h2>
            <div className="flex lg:flex-row items-center space-x-4">
              <NavLink
                to="/coach/library/create"
                className="h-fit w-fit flex flex-row items-center justify-center gap-x-2 py-4 px-5 text-white text-[14px] font-bold uppercase bg-[#26A7EF] rounded-[12px] hover:text-white md:relative md:rounded-[30px]"
              >
                <AddIcon />
                <span className="hidden md:block">Создать материал</span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto p-4">
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <img
              src="https://via.placeholder.com/300x200"
              alt="Poster Image"
              className="w-full h-48 object-cover"
            />
            <div className="p-4 theme-student-card">
              <h2 className="text-xl font-semibold">Card Title</h2>
              <div className="flex justify-end mt-4 space-x-2">
                <button className="px-4 py-2h-fit w-fit flex flex-row items-center rounded-md justify-center gap-x-2 py-4 text-white text-[14px] font-bold uppercase bg-[#26A7EF]  hover:text-white ">
                  Edit
                </button>
                <button className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600">
                  Delete
                </button>
              </div>
            </div>
          </div>

          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <img
              src="https://via.placeholder.com/300x200"
              alt="Poster Image"
              className="w-full h-48 object-cover"
            />
            <div className="p-4 theme-student-card">
              <h2 className="text-xl font-semibold">Card Title</h2>
              <div className="flex justify-end mt-4 space-x-2">
                <button className="px-4  w-fit flex flex-row items-center rounded-md justify-center gap-x-2 py-4 text-white text-[14px] font-bold uppercase bg-[#26A7EF]  hover:text-white ">
                  Edit
                </button>
                <button className="px-4  bg-red-500 text-white rounded-md hover:bg-red-600">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
