import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as DocIcon } from 'assets/icons/file.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pen.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import Loader from 'components/Loader';
import PDFView from 'components/PDF';
import { deleteLesson } from 'modules/coach/api/repository/delete-lesson';
import { fetchLessonsDetail } from 'modules/coach/api/repository/lesson-detail';
import { ILessonDetail } from 'modules/coach/entities/LssonDetail';
import { ISingleLessonFiles } from 'modules/student/entities/Lesson';
import { useConfirmBox } from 'hooks/confirm-box';
import { useNotification } from 'hooks/notification';

import FileDownloadButton from '../DownLoadButton';

const LessonDetail = ({ id }: { id: number }) => {
  const [lessonDetail, setLessonDetail] = useState<ILessonDetail>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const notify = useNotification();
  const navigate = useNavigate();
  const { showConfirmBox } = useConfirmBox();

  useEffect(() => {
    setIsLoading(true);
    fetchLessonsDetail(id)
      .then((res) => {
        setLessonDetail(res);
      })
      .catch(() => notify.error('Что-то пошло не так'))
      .finally(() => setIsLoading(false));
  }, [id]);

  const removeLesson = () => {
    showConfirmBox({
      message: 'Вы уверены, что хотите удалить урок?',
      okText: 'Да',
      cancelText: 'Нет',
      cancelVariant: 'red',
      onAccept: () => {
        deleteLesson(Number(id))
          .then(() => {
            notify.success('Урок успешно удален');
            setTimeout(() => {
              navigate('/coach/lessons');
            }, 700);
          })
          .catch((e) => {
            if (e.response.status === 500) {
              notify.error('Произошла ошибка при удалении урока');
            }
            if (e.response.status === 403) {
              notify.error('У вас нет прав для удаления урока');
            }
          });
      },
    });
  };

  const mapFiles = (files: ISingleLessonFiles[]) => {
    return {
      pdf: files.filter((file: ISingleLessonFiles) => file.name.includes('pdf')),
      without: files.filter((file: ISingleLessonFiles) => !file.name.includes('pdf')),
    };
  };

  const configureYoutubeUrl = (url: string) => {
    if (url.startsWith('https://youtu')) {
      return url.replace('https://youtu.be', 'https://youtube.com/embed');
    }

    if (url.startsWith('https://www.youtube')) {
      return url.replace('https://www.youtube.com/watch?v=', 'https://youtube.com/embed/');
    }

    return url;
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col justify-between gap-3 md:flex-row md:items-end">
        {lessonDetail?.position && lessonDetail?.name && (
          <LMSTitle tag="h4" className="flex-1 text-current text-3xl font-medium overflow-hidden">
            Урок {lessonDetail?.position}. {lessonDetail?.name}
          </LMSTitle>
        )}

        <div className="flex flex-row gap-x-2">
          <LMSButton
            prefix={<EditIcon />}
            onClick={() => navigate(`/coach/lessons/lesson/edit/${id}`)}
          >
            РЕДАКТИРОВАТЬ УРОК
          </LMSButton>
          <button
            onClick={() => removeLesson()}
            className="w-[55px] h-[55px] flex justify-center items-center p-0 text-current theme-icon-stroke border theme-border--active bg-white bg-opacity-20 cursor-pointer rounded-full"
          >
            <TrashIcon />
          </button>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div className="h-fit w-full flex flex-col mt-10 space-y-10 p-10 theme-student-card rounded-[20px] overflow-hidden text-xl theme-text--secondary">
          {lessonDetail?.description && (
            <div
              dangerouslySetInnerHTML={{ __html: lessonDetail.description || '' }}
              className="text-lg student-description text-current"
            ></div>
          )}

          {lessonDetail?.videoYoutubeUrl &&
          (lessonDetail.videoYoutubeUrl.startsWith('https://youtu') ||
            lessonDetail.videoYoutubeUrl.startsWith('https://www.youtube')) ? (
            <div className="flex flex-col gap-y-7 aspect-video">
              <span className="text-current text-3xl">YouTube</span>
              <div className="aspect-video w-full rounded-xl overflow-hidden">
                <iframe
                  className="w-full h-full"
                  src={configureYoutubeUrl(lessonDetail?.videoYoutubeUrl)}
                  title={lessonDetail?.name}
                  frameBorder={0}
                  allowFullScreen
                />
              </div>
            </div>
          ) : null}

          {/* {lessonDetail?.videoBroadcastUrl &&
          lessonDetail?.videoBroadcastUrl.startsWith('https://') ? (
            <a
              href={String(lessonDetail?.videoBroadcastUrl)}
              target="_blank"
              rel="noreferrer"
              className="w-fit flex justify-center items-center gap-x-3 mx-auto px-6 py-4 text-[16px] text-[#212121] bg-white rounded-full cursor-pointer"
            >
              <CameraIcon />
              Перейти на трансляцию
            </a>
          ) : null} */}
        </div>
      )}

      <div className="w-full flex flex-col gap-5 mt-16">
        <h4 className="flex flex-row items-center gap-x-2 theme-icon-stroke">
          <span className="theme-text--secondary text-3xl font-medium" style={{ color: 'black' }}>
            {' '}
            Материалы
          </span>
          <DocIcon />
        </h4>
        <div className="flex flex-wrap gap-4">
          {lessonDetail?.fileUrl?.length ? (
            <div className="flex flex-col space-y-5 mt-5">
              {mapFiles(lessonDetail?.fileUrl).pdf?.map((file) => (
                <div key={file?.downloadLink} className="mb-4">
                  <PDFView fileLink={file?.downloadLink} />
                </div>
              ))}
              {mapFiles(lessonDetail?.fileUrl).without?.map((file) => (
                <div
                  key={file?.downloadLink}
                  className="flex items-center justify-between px-8 py-6 rounded-xl theme-student-card"
                >
                  <div>
                    <p className="flex flex-col text-xl">
                      {file?.name}
                      <span className="text-current text-opacity-30">
                        {(Number(file.size) / (1024 * 1024)).toFixed(2)} мб
                      </span>
                    </p>
                  </div>
                  <a
                    href={file?.downloadLink}
                    target="_blank"
                    className="flex flex-col items-center space-y-3 theme-text--secondary theme-icon-stroke"
                    rel="noreferrer"
                  >
                    <UploadIcon />
                    <span>Скачать</span>
                  </a>
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{ color: 'black' }}
              className="flex flex-col mt-4 mx-auto text-xl text-center text-current text-opacity-25"
            >
              Материалы отсутствуют{' '}
              <NavLink
                to={`/coach/lessons/lesson/edit/${id}`}
                className="hover:underline hover:underline-offset-4"
              >
                Добавить материал +
              </NavLink>
            </div>
          )}
        </div>
      </div>

      <div className="w-full flex flex-col gap-5 mt-16">
        <h4 className="flex flex-row items-center gap-x-2 theme-icon-stroke">
          <span className="theme-text--secondary text-3xl font-medium" style={{ color: 'black' }}>
            Видео материалы
          </span>
          <DocIcon />
        </h4>
        <div className="flex flex-wrap gap-4">
          {lessonDetail?.videoUrl?.length ? (
            lessonDetail?.videoUrl?.map((el, idx) => (
              <div
                key={idx}
                className="w-[48%] flex flex-row justify-between items-center p-7 theme-student-card rounded-[14px]"
              >
                <div className="max-w-[400px] flex flex-col gap-2">
                  <p className="flex flex-row items-end gap-x-2 text-current text-ld">
                    {el.name}
                    <span className="text-current text-opacity-30 text-base">
                      {(Number(el.size) / (1024 * 1024)).toFixed(2)} мб
                    </span>
                  </p>
                </div>
                <FileDownloadButton fileLink={el.downloadLink} name={el.name} />
              </div>
            ))
          ) : (
            <div className="flex flex-col mt-4 mx-auto text-xl text-center text-white text-opacity-25">
              Материалы отсутствуют
              <NavLink
                to={`/coach/lessons/lesson/edit/${id}`}
                className="hover:underline hover:underline-offset-4"
              >
                Добавить материал +
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LessonDetail;
