import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import LMSButton from 'components/LMSButton';
import LMSInput from 'components/LMSInput';
import LMSRadioButton from 'components/LMSRadioButton';
import LMSToggle from 'components/LMSToggle';
import { authSlice } from 'store/auth';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ErrorResponse } from 'entities/Error';

import { changeAvatar, editProfile } from '../../api/repository/profile';

interface ProfileForm {
  phone: string;
  localCity: string;
  getNotifications: boolean;
}

const EditProfile = () => {
  const notify = useNotification();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { handleCommonError } = useHandleError();

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isLoading },
  } = useForm<ProfileForm>({
    defaultValues: {
      phone: user?.phone || '',
      localCity: user?.locationCity || '',
      getNotifications: false,
    },
  });

  const getNotifications = watch('getNotifications');

  const onUploadAvatar = async (files: null | FileList) => {
    if (!files || !files.length || !user) return;

    const file = files[0];
    const fd = new FormData();
    fd.append('avatar', file);
    await changeAvatar(fd);
    notify.success('Данные успешно сохранены');

    updateLocalUser(file);
  };

  const updateLocalUser = (file: File) => {
    if (!user) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const preview = event.target?.result as string;
      dispatch(authSlice.actions.userFetchingSuccess({ ...user, avatar: preview }));
    };

    reader.readAsDataURL(file);
  };

  const onSubmit = async (form: ProfileForm) => {
    const data = {
      location_city: form.localCity,
      phone: form.phone,
    };
    try {
      await editProfile(data);
      notify.success('Данные успешно сохранены');
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      handleCommonError(error);
    }
  };

  if (!user) return <div />;

  return (
    <div className="mt-12">
      <div className="flex justify-between items-end">
        <div>
          <h2 className="text-2xl md:text-[32px]" style={{ color: 'black' }}>
            Редактировать профиль
          </h2>
          <p className="text-base md:text-lg " style={{ color: 'black' }}>
            Данный профиль является корпоративным
          </p>
        </div>
        <LMSButton loading={isLoading} className="hidden md:flex" onClick={handleSubmit(onSubmit)}>
          СОХРАНИТЬ ИЗМЕНЕНИЯ
        </LMSButton>
      </div>
      <div className="flex flex-col-reverse md:grid grid-cols-[1fr_250px] gap-6 mt-6">
        <div className="flex-1 p-5 md:p-10 rounded-2xl theme-student-card">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <LMSInput name="fio" label="ФИО" value={user.fio} onChange={(value) => {}} disabled />
            <LMSInput
              name="birthDate"
              label="Дата рождения"
              value={user.birthDate}
              onChange={(value) => {}}
              disabled
            />
            <div className="hidden xl:block"></div>
            <div className="flex space-x-3">
              <label className="flex space-x-2.5 cursor-not-allowed">
                <LMSRadioButton checked={user.gender !== 'мужской'} />
                <span>Женщина</span>
              </label>
              <label className="flex space-x-2.5 cursor-not-allowed">
                <LMSRadioButton checked={user.gender === 'мужской'} />
                <span>Мужчина</span>
              </label>
            </div>
          </div>

          <div className="mt-9">
            <LMSInput
              name="position"
              label="Должность/специализация"
              value={user.positionFamily}
              disabled
              onChange={(value) => {}}
            />
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-5">
            <Controller
              control={control}
              name="phone"
              rules={{ required: { message: 'Обязателен к заполнению', value: true } }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <LMSInput
                  name="phoneNumber"
                  label="Телефон"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              control={control}
              name="localCity"
              rules={{ required: { message: 'Обязателен к заполнению', value: true } }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <LMSInput
                  name="localCity"
                  label="Город"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </div>

          <div className="mt-9">
            <LMSInput
              name="email"
              label="Почта"
              value={user.email}
              disabled
              onChange={(value) => {}}
            />
          </div>
        </div>
        <div className="shrink-0 flex flex-col items-center h-fit w-full xs:w-[250px] p-6 rounded-2xl theme-student-card">
          <p className="mb-5 text-2xl text-center">Аватар</p>

          <div className="w-28 h-28 mb-10 rounded-full overflow-hidden">
            {user?.avatar ? (
              <img className="w-full h-full object-cover" src={user?.avatar} alt={user?.fio} />
            ) : (
              <img src={require('../../../../assets/images/empty-avatar.png')} alt="" />
            )}
          </div>

          <label className="relative w-full py-2 text-center rounded-full border border-solid theme-input bg-transparent cursor-pointer">
            <span className="text-sm">Загрузить фото</span>
            <input
              type="file"
              className="absolute t-0 w-[1px] h-[1px] opacity-0"
              onChange={(event) => onUploadAvatar(event.target.files)}
            />
          </label>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-[1fr_250px] gap-6 mt-6">
        <div className="flex items-center space-x-4 p-5 md:p-10 rounded-2xl theme-student-card">
          <LMSToggle
            value={getNotifications}
            onChange={(val) => {
              setValue('getNotifications', val);
            }}
          />
          <p>Я хочу получать уведомления об образовательных программах</p>
        </div>
      </div>
      <div className="mt-6">
        <LMSButton
          loading={isLoading}
          className="md:hidden w-full"
          onClick={handleSubmit(onSubmit)}
        >
          СОХРАНИТЬ ИЗМЕНЕНИЯ
        </LMSButton>
      </div>
    </div>
  );
};

export default EditProfile;
