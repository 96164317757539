interface SwitchTabsProps {
  activeTab: number;
  leftTabName?: string;
  rightTabName?: string;
  onTabChange: (tabIndex: number) => void;
}

const SwitchTabs: React.FC<SwitchTabsProps> = ({
  activeTab,
  onTabChange,
  leftTabName,
  rightTabName,
}) => {
  return (
    <div className="w-full h-fit flex flex-row mx-auto text-[16px] font-medium sm:w-[60%]">
      <div
        onClick={() => onTabChange(1)}
        className={`flex flex-1 justify-center items-center border-b-2 text-xl text-center py-4 theme-text-tabs cursor-pointer ${
          activeTab === 1
            ? 'theme-text--active-tabs theme-border-tabs-active'
            : 'text-opacity-20 theme-border-tabs theme-border-tabs-disabled'
        }`}
      >
        {leftTabName ? leftTabName : 'Активные'}
      </div>
      <div
        onClick={() => onTabChange(0)}
        className={`flex flex-1 justify-center items-center border-b-2 text-xl text-center py-4 theme-text-tabs cursor-pointer ${
          activeTab === 0
            ? 'theme-text--active-tabs theme-border-tabs-active'
            : 'text-opacity-20 theme-border-tabs theme-border-tabs-disabled'
        }`}
      >
        {rightTabName ? rightTabName : 'Завершенные'}
      </div>
    </div>
  );
};

export default SwitchTabs;
