import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOutsideClick } from 'hooks/outside-click';

export interface DropdownOption {
  id: number;
  title: string;
  path?: string;
  icon?: React.ReactNode;
  onClick?: (id?: number) => void;
}

type DropdownPosition = 'right' | 'left';

interface DropdownProps {
  targetId?: number;
  options: DropdownOption[];
  position?: DropdownPosition;
  children: React.ReactNode;
}

const LMSDropdown: React.FC<DropdownProps> = ({
  targetId = 0,
  options,
  position = 'right',
  children,
}) => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const dropdownRef = useOutsideClick(() => {
    setOpen(false);
  });

  const toggleDropdown = () => {
    setOpen(!isOpen);
  };

  const onOptionClick = (option: DropdownOption) => {
    if (option.path) {
      navigate(option.path);
    } else {
      option.onClick && option.onClick(targetId);
    }
    toggleDropdown();
  };

  const listClass = useMemo(() => {
    return `
      absolute bg-white rounded-2xl translate-y-3 z-10
      ${isOpen ? 'block' : 'hidden'}
      ${position === 'right' ? 'right-0' : 'left-0'}
    `;
  }, [isOpen, position]);

  return (
    <div ref={dropdownRef} className="relative">
      <button onClick={toggleDropdown}>{children}</button>
      <div
        className={listClass}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <ul className="my-2">
          {options.map((option) => (
            <li key={option.id}>
              <button
                className="flex items-center space-x-2.5 w-full py-2 px-3 bg-transparent text-black theme-active-effect--hover"
                onClick={() => onOptionClick(option)}
              >
                {option.icon && (
                  <span style={{ color: 'rgba(37, 59, 209, 1)' }}>{option.icon}</span>
                )}
                <span className="whitespace-nowrap" style={{ color: 'rgba(37, 59, 209, 1)' }}>
                  {option.title}
                </span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LMSDropdown;
