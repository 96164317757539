import React, { useEffect, useMemo, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import LMSButton from 'components/LMSButton';
import LMSInput from 'components/LMSInput';
import ru from 'date-fns/locale/ru';
import { useOutsideClick } from 'hooks/outside-click';
import { useWindowSize } from 'hooks/window-size';
import { Option } from 'entities/Option';

import { fetchJournalCourses } from '../../api/repository/courses';
import { fetchGroupsCourses } from '../../api/repository/groups';
import DropdownSelect from '../../components/DropdownSelect';
import { useJournalBaseFilter } from '../../hooks/journal-base-filter';

registerLocale('ru', ru);

const JournalBaseFilter = () => {
  const device = useWindowSize();
  const { setFilter, onSubmit, onReset } = useJournalBaseFilter();
  const [query, setQuery] = useSearchParams();

  const course_id = query.get('course_id');
  const group_id = query.get('group_id');
  const date_from = query.get('date_from');
  const date_to = query.get('date_to');
  const fio = query.get('fio');

  const [isOpen, setOpen] = useState(false);
  const [fullName, setFullName] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | undefined | null>(null);
  const [endDate, setEndDate] = useState<Date | undefined | null>(null);
  const [course, setCourse] = useState<{ id: number | undefined; name: string }>({
    id: undefined,
    name: '',
  });
  const [group, setGroup] = useState<{ id: number | undefined; name: string }>({
    id: undefined,
    name: '',
  });
  const [courses, setCourses] = useState<Option[]>([]);
  const [groups, setGroups] = useState<Option[]>([]);

  useEffect(() => {
    setFullName(fio ?? '');
    setStartDate(date_from ? new Date(date_from) : null);
    setEndDate(date_to ? new Date(date_to) : null);

    setCourse({
      id: course_id ? +course_id : undefined,
      name: course_id ? courses.find((el) => el.id === +course_id)?.label || '' : '',
    });
    setGroup({
      id: group_id ? +group_id : undefined,
      name: group_id ? groups.find((el) => el.id === +group_id)?.label || '' : '',
    });
  }, []);

  useEffect(() => {
    fetchGroupsCourses({
      course_id: course.id ?? '',
      perPage: 30,
    })
      .then(({ groups }) => {
        setGroups(
          /* eslint-disable-next-line */
          groups.map((group: any) => ({
            value: group.id,
            label: group.name,
          }))
        );
      })
      .catch((e) => console.error(e));
  }, [course]);

  useEffect(() => {
    fetchJournalCourses({
      group_id: group!.id ?? '',
      perPage: 30,
    }).then(({ courses }) => {
      setCourses(
        /* eslint-disable-next-line */
        courses.map((course: any) => ({
          value: course.id,
          label: course.name,
        }))
      );
    });
  }, [group]);

  const filterRef = useOutsideClick(() => {
    if (device !== 'desktop') {
      setOpen(false);
    }
  });

  const filterClass = useMemo(() => {
    if (device === 'desktop') {
      return 'relative w-full h-fit flex flex-col';
    }

    if (!isOpen) return 'hidden';

    return 'fixed bottom-0 right-0 left-0 top-24 w-full bg-[#3D436B] px-8 py-5 border border-solid border-[#3D436B] rounded-t-[20px] overflow-auto z-10';
  }, [device, isOpen]);

  const onToggleOpen = () => {
    if (device === 'desktop') return;

    if (isOpen) {
      document.body.classList.remove('modal-open');
    } else {
      document.body.classList.add('modal-open');
    }
    setOpen((prev) => !prev);
  };

  const resetFilters = () => {
    setFullName('');
    setStartDate(null);
    setEndDate(null);
    setCourse({ id: undefined, name: '' });
    setGroup({ id: undefined, name: '' });
    onToggleOpen();
    onReset();
  };

  const submitFilters = () => {
    onToggleOpen();
    onSubmit();
  };

  useEffect(() => {
    setFilter({
      fio: fullName || null,
      course_id: course?.id ? String(course.id) : null,
      group_id: group?.id ? String(group.id) : null,
      date_from: startDate ? new Date(startDate).toLocaleDateString('sv-SE') : null,
      date_to: endDate ? new Date(endDate).toLocaleDateString('sv-SE') : null,
    });
  }, [course, group, fio, startDate, endDate]);

  return (
    <div>
      <button
        className="flex lg:hidden items-center justify-center w-full mb-6 p-2 space-x-2 border border-white border-opacity-30 rounded-xl"
        onClick={onToggleOpen}
      >
        <FilterIcon />
        <span>Фильтр</span>
      </button>

      <div ref={filterRef} className={filterClass}>
        <div className="w-full flex flex-col gap-2 md:flex-row md:flex-wrap">
          <div className="w-full md:w-fit md:max-w-[360px]">
            <DropdownSelect
              notRounded={true}
              title="Выберите курс"
              value={course.name}
              list={courses}
              onCourseClick={(id, name) => {
                setCourse({ id, name });
              }}
            >
              <div className="flex items-center space-x-4 w-full py-3 px-4 rounded-full border border-white/10">
                <span>Выберите курс</span>
                <ArrowDownIcon />
              </div>
            </DropdownSelect>
          </div>
          <div className="w-full md:w-fit md:max-w-[360px]">
            <DropdownSelect
              notRounded={true}
              title="Выберите группу"
              value={group.name}
              list={groups}
              onCourseClick={(id, name) => {
                setGroup({ id, name });
              }}
            >
              <div className="flex items-center space-x-4 w-full py-3 px-4 rounded-full border border-white/10">
                <span>Выберите группу</span>
                <ArrowDownIcon />
              </div>
            </DropdownSelect>
          </div>
          <div className="training-base-datepicker w-full flex flex-row md:w-fit md:max-w-[360px]">
            <DatePicker
              selected={startDate}
              placeholderText="Дата обучения"
              locale="ru"
              showTimeSelect
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="dd:MM:yyyy"
              onChange={(date) => setStartDate(date)}
              className="text-black"
            />
            <CalendarIcon className="ml-[-44px] mt-[14px] text-white lg:ml-[-30px]" />
          </div>
          <div className="training-base-datepicker w-full flex flex-row md:w-fit md:max-w-[360px]">
            <DatePicker
              selected={endDate}
              placeholderText="Дата завершения"
              locale="ru"
              showTimeSelect
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="dd:MM:yyyy"
              onChange={(date) => setEndDate(date)}
              className="text-black"
            />
            <CalendarIcon className="ml-[-44px] mt-[14px] text-white lg:ml-[-30px]" />
          </div>
          <div className="w-full md:w-fit md:max-w-[360px]">
            <LMSInput
              name="fullName"
              placeholder="ФИО"
              variant="rounded"
              value={fullName}
              onChange={(e: string) => setFullName(e)}
            />
          </div>
        </div>
        <div className="w-full flex flex-col gap-4 mt-4 md:flex-row">
          {device === 'desktop' ? (
            <>
              <button
                type="button"
                className="w-fit flex-row gap-x-2 text-white md:flex"
                onClick={submitFilters}
                style={{ color: 'black' }}
              >
                &#10003; <span className="underline underline-offset-4">Применить</span>
              </button>
              <button
                type="button"
                className="w-fit flex-row gap-x-2 text-white md:flex"
                onClick={resetFilters}
                style={{ color: 'black' }}
              >
                x <span className="underline underline-offset-4">Сбросить фильтры</span>
              </button>
            </>
          ) : (
            <>
              <LMSButton className="w-full mt-5" colorType="light" onClick={submitFilters}>
                Применить
              </LMSButton>
              <LMSButton
                className="w-full"
                variant="text"
                prefix={<CloseIcon className="w-5 h-5" />}
                onClick={resetFilters}
              >
                Сбросить
              </LMSButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default JournalBaseFilter;
