import React from 'react';
import { NavLink } from 'react-router-dom';

const CheckAssignmentTabs = () => (
  <div className="flex justify-center items-end">
    <NavLink
      to="/coach/check-assignments/homeworks"
      className={({ isActive }) => {
        return `
            flex-1 border-b-2 text-xl text-center py-4 theme-text-tabs
            ${
              isActive
                ? 'theme-text--active-tabs theme-border-tabs-active'
                : 'text-opacity-20 theme-border-tabs theme-border-tabs-disabled'
            }
          `;
      }}
    >
      Домашние задания
    </NavLink>
    <NavLink
      to="/coach/check-assignments/tests"
      className={({ isActive }) => {
        return `
            flex-1 border-b-2 text-xl text-center py-4 theme-text-tabs
            ${
              isActive
                ? 'theme-text--active-tabs theme-border-tabs-active'
                : 'text-opacity-20 theme-border-tabs theme-border-tabs-disabled'
            }
          `;
      }}
    >
      Тесты
    </NavLink>
  </div>
);

export default CheckAssignmentTabs;
