import React, { useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as HomeWorkIcon } from 'assets/icons/homework.svg';
import { useAppSelector } from 'hooks/redux';

import TrainerCard from '../../modules/student/components/TrainerCard/index';
import { Lesson } from '../../modules/student/entities/Lesson';

interface Props {
  lessons: Lesson[];
}

const CalendarPopup: React.FC<Props> = ({ lessons }) => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const isStudent = useMemo(() => {
    return user && user.currentRoleId === 4;
  }, [user]);

  type MouseEvent = React.MouseEvent;

  const jumpToAttendance = (e: MouseEvent, calendarId: number) => {
    e.preventDefault();
    if (!calendarId) throw new Error('calendar_id is not defined');
    navigate(`/coach/lessons/lesson/attendance/${calendarId}`);
  };

  if (!lessons.length) return <div></div>;

  return (
    <div id="calendar-popup" className="calendar-popup relative z-40 cursor-move">
      {lessons.map((lesson) => (
        <NavLink
          to={
            isStudent
              ? `/student/active/${lesson.courseId}/lessons/${lesson.id}`
              : `/coach/lessons/lesson/${lesson.id}`
          }
          className="relative"
          key={lesson.id}
        >
          <div>
            <span className="text-[14px] theme-text--active font-medium mr-1">
              {lesson?.courseName}
            </span>
            {!isStudent && (
              <button
                onClick={(e) => jumpToAttendance(e, Number(lesson.scheduleId))}
                className="right-[10px] text-[14px] font-medium z-100"
              >
                Посещаемость
              </button>
            )}
          </div>
          <span className="text-[20px] theme-text--black font-medium truncate max-w-[250px]">
            {lesson?.name}
          </span>
          <span className="text-[15px] theme-text--disabled font-medium flex gap-2 items-center">
            <ClockIcon className="blue" />
            {lesson?.timeFrom.slice(0, 5)}-{lesson?.timeTo.slice(0, 5)}
          </span>
          {/* {lesson?.homeworkDeadline ? (
          {lesson && lesson.homeworkDeadline && typeof lesson.homeworkDeadline === 'string' ? (
            <span className="flex gap-2 items-center text-[15px] theme-text--disabled font-medium">
              <HomeWorkIcon className="blue" />
              {lesson?.homeworkDeadline}
            </span>
          ) : null} */}
          {lesson?.testId ? (
            <span className="flex gap-2 items-center text-[15px] theme-text--disabled font-medium">
              <HomeWorkIcon className="blue" />
              {lesson?.testId && 'Тест'}
            </span>
          ) : null}
          <div className="max-w-[250px]">
            <TrainerCard trainerInfo={lesson?.coach} />
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default CalendarPopup;
