import { useState } from 'react';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down.svg';
import { useOutsideClick } from 'hooks/outside-click';
import { Option } from 'entities/Option';

export type Props = {
  notRounded?: boolean;
  title: string;
  list: Option[];
  value?: string;
  disabled?: boolean;
  error?: string;
  onCourseClick: (id: number, name: string) => void;
  className?: string;
  children?: React.ReactNode;
};

const DropdownSelect: React.FC<Props> = ({
  notRounded,
  title,
  list,
  value,
  disabled,
  error,
  onCourseClick,
  className,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dropdownRef = useOutsideClick(() => {
    setIsOpen(false);
  });

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleItemClick = (id: number, name: string) => {
    setIsOpen(false);
    if (onCourseClick) {
      onCourseClick(id, name);
    }
  };

  const maxStringLength = list.reduce((maxLength, el) => Math.max(maxLength, el.label.length), 0);
  const dropdownWidth = `${maxStringLength * 8 + 38}px`;

  return (
    <div ref={dropdownRef} className="relative h-full">
      <button
        onClick={() => toggleDropdown()}
        disabled={disabled}
        className={`${notRounded ? 'rounded-[14px] bg-inputbg' : 'rounded-[55px]'} ${
          error ? 'border-red-500' : 'border-black'
        } relative w-full max-h-[52px] flex flex-row items-center justify-between gap-x-8 py-3 px-6 text-white border border-opacity-50 disabled:cursor-not-allowed md:h-fit ${className}`}
        style={{ color: 'black' }}
      >
        <span className="my-auto text-[16px] whitespace-nowrap overflow-x-auto">
          {value ? value : title}
        </span>
        <ArrowDownIcon
          className={`${
            isOpen ? 'rotate-180 ease-out duration-700' : 'ease-in duration-500'
          } w-fit`}
        />
      </button>
      {isOpen && (
        <div
          style={{ width: dropdownWidth }}
          className="absolute top-14 left-0 h-fit min-w-[200px] max-w-[300px] mt-1 border border-white border-opacity-50 rounded-lg bg-homeworkgradient z-50"
        >
          <ul className="max-h-[168px] w-full flex flex-col overflow-y-auto">
            {list.length ? (
              list.map((el) => (
                <li
                  key={el.value}
                  onClick={() => handleItemClick(el.value, el.label)}
                  className="w-full py-2 px-5 text-white text-opacity-50 text-start cursor-pointer hover:bg-inputbg hover:text-opacity-100"
                >
                  {el.label}
                </li>
              ))
            ) : (
              <li className="py-2 px-5 text-white text-sm text-opacity-50 text-start">
                Нет подходящих элементов
              </li>
            )}
          </ul>
        </div>
      )}
      {!!error ? (
        <div className="pt-1">
          <p className="text-[red]">{error}</p>
        </div>
      ) : null}
    </div>
  );
};

export default DropdownSelect;
