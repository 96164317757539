import { createBrowserRouter } from 'react-router-dom';
import Auth from 'layouts/auth';
import CoachLayout from 'layouts/coach';
import Default from 'layouts/default';
import HrLayout from 'layouts/hr';
import StudentLayout from 'layouts/student';
import Login from 'modules/auth/views';
import CoachLogin from 'modules/auth/views/coach-auth';
import CoachRegister from 'modules/auth/views/coach-registration';
import StudentLogin from 'modules/auth/views/student-auth';
import StudentRegister from 'modules/auth/views/student-registration';
import CheckAssignments from 'modules/coach/views/check-assignments';
import CheckHomeWork from 'modules/coach/views/check-homework';
import CheckHomeworks from 'modules/coach/views/check-homeworks';
import CheckHomeWorkForTest from 'modules/coach/views/check-homeworks-for-test';
import CheckTests from 'modules/coach/views/check-tests';
import CheckedHomeworks from 'modules/coach/views/checked-homeworks';
import CoachCourseDetail from 'modules/coach/views/courses/detail';
import CoachCoursesMain from 'modules/coach/views/courses/main';
import CoachCourseStudent from 'modules/coach/views/courses/student';
import EditHomeWork from 'modules/coach/views/edit-home-work';
import EditLessonDetail from 'modules/coach/views/edit-lesson-detail';
import EditCoucheProfile from 'modules/coach/views/edit-profile';
import EditSchedule from 'modules/coach/views/edit-schedule';
import CoachEditSimulation from 'modules/coach/views/edit-simulation';
import TestEdit from 'modules/coach/views/edit-test';
import HomeWorkDetail from 'modules/coach/views/home-work-detail';
import HomeWorks from 'modules/coach/views/home-works';
import HomeWorksGroups from 'modules/coach/views/home-works-groups';
import HomeworksForCheck from 'modules/coach/views/homeworks-for-check';
import CoachJournal from 'modules/coach/views/journal';
import LessonAttendance from 'modules/coach/views/lesson-attendance';
import CoachLessonDetail from 'modules/coach/views/lesson-detail';
import Lessons from 'modules/coach/views/lessons';
import NewHomework from 'modules/coach/views/new-home-work';
import NewLessons from 'modules/coach/views/new-lesson';
import NewSchedule from 'modules/coach/views/new-schedule';
import CoachNewSimulation from 'modules/coach/views/new-simulation';
import Password from 'modules/coach/views/password';
import Schedule from 'modules/coach/views/schedule';
import CoachSimulationDetail from 'modules/coach/views/simulation-detail';
import CoachSimulations from 'modules/coach/views/simulations';
import TestCreate from 'modules/coach/views/test-create';
import TestCoachDetail from 'modules/coach/views/test-detail';
import CoachTests from 'modules/coach/views/tests';
import NotFound from 'modules/errors/404';
import HRApplications from 'modules/hr/views/applications';
import HrCoachDetail from 'modules/hr/views/coach-detail';
// import InProgress from 'modules/errors/in-progress';
import CoachList from 'modules/hr/views/coach-list';
import HrCourseDetail from 'modules/hr/views/course-detail';
import HrCourses from 'modules/hr/views/courses';
import HrDashboard from 'modules/hr/views/dashboard';
import HrEditCourseDetail from 'modules/hr/views/edit-course-detail';
import HrEditGroupDetail from 'modules/hr/views/edit-group-detail';
import EditHrProfile from 'modules/hr/views/edit-profile';
import HrGroupDetail from 'modules/hr/views/group-detail';
import HrGroups from 'modules/hr/views/groups';
import HRJournal from 'modules/hr/views/journal';
import HRJournalDetail from 'modules/hr/views/journal-detail';
import HrManagerDetail from 'modules/hr/views/manager-detail';
import Managers from 'modules/hr/views/managers';
import NewCoach from 'modules/hr/views/new-coach';
import NewCourse from 'modules/hr/views/new-course';
import HrNewGroup from 'modules/hr/views/new-group';
import NewManager from 'modules/hr/views/new-manager';
import NewStudent from 'modules/hr/views/new-student';
import HrPassword from 'modules/hr/views/password';
import HrStudentDetail from 'modules/hr/views/student-detail';
import HrStudents from 'modules/hr/views/students';
import HrTraining from 'modules/hr/views/training';
import Users from 'modules/hr/views/users';
import Kinescope from 'modules/kinescope/views';
import AllCourses from 'modules/landing/views/courses';
import Landing from 'modules/landing/views/landing';
// import QR from 'modules/QR';
import ActiveCourseDetail from 'modules/student/views/active-course-detail';
import ActiveCourseLayout from 'modules/student/views/active-course-layout';
import ActiveCourses from 'modules/student/views/active-courses';
import BookDetail from 'modules/student/views/book-detail';
import CompletedCourses from 'modules/student/views/competed-courses';
import CountQuickly from 'modules/student/views/count-quickly';
import CommonCourseDetail from 'modules/student/views/course-detail';
import Courses from 'modules/student/views/courses';
import EditProfile from 'modules/student/views/edit-profile';
import FaqStudent from 'modules/student/views/faq';
import HomeworkDetail from 'modules/student/views/homework-detail';
import LessonDetail from 'modules/student/views/lesson-detail';
import Library from 'modules/student/views/library';
import MathVortex from 'modules/student/views/math-vortex';
import MyCourses from 'modules/student/views/my-courses';
import Pathfinder from 'modules/student/views/pathfinder';
import ProfileLayout from 'modules/student/views/profile-layout';
import ResetPasswordStudent from 'modules/student/views/reset-password';
import Simulations from 'modules/student/views/simulations';
import StudentSurvey from 'modules/student/views/survey';
import TestDetail from 'modules/student/views/test-detail';
import UI from 'modules/UI';

import { CoachLibraryCreate } from '../modules/coach/views/library/create';
import { CoachLibraryList } from '../modules/coach/views/library/list';

export const router = createBrowserRouter([
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: 'auth',
    element: <Auth />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'student-login',
        element: <StudentLogin />,
      },
      {
        path: 'student-register',
        element: <StudentRegister />,
      },
      {
        path: 'coach-login',
        element: <CoachLogin />,
      },
      {
        path: 'coach-register',
        element: <CoachRegister />,
      },
    ],
  },
  {
    path: '/',
    element: <Landing />,
  },
  {
    path: '/courses',
    element: <AllCourses />,
  },
  {
    path: '/',
    element: <Default />,
    children: [
      {
        path: 'player/:id',
        element: <Kinescope />,
      },
      {
        path: 'student',
        element: <StudentLayout />,
        children: [
          {
            element: <MyCourses />,
            children: [
              {
                index: true,
                element: <ActiveCourses />,
              },
              {
                path: 'completed',
                element: <CompletedCourses />,
              },
            ],
          },
          {
            path: 'active/:id',
            element: <ActiveCourseLayout />,
            children: [
              {
                index: true,
                element: <ActiveCourseDetail />,
              },
              {
                path: 'lessons/:lessonId',
                element: <LessonDetail />,
              },
              {
                path: 'homeworks/:lessonId',
                element: <HomeworkDetail />,
              },
              {
                path: 'tests/:testId',
                element: <TestDetail />,
              },
            ],
          },
          {
            path: 'library',
            element: <Library />,
          },
          {
            path: 'library/:id',
            element: <BookDetail />,
          },
          {
            path: 'courses',
            element: <Courses />,
          },
          {
            path: 'courses/:id',
            element: <CommonCourseDetail />,
          },
          {
            path: 'faq',
            element: <FaqStudent />,
          },
          {
            path: 'profile',
            element: <ProfileLayout />,
            children: [
              {
                index: true,
                element: <EditProfile />,
              },
              {
                path: 'password',
                element: <ResetPasswordStudent />,
              },
              {
                path: 'survey/:id',
                element: <StudentSurvey />,
              },
            ],
          },
          {
            path: 'math-vortex',
            element: <MathVortex />,
          },
          {
            path: 'simulations',
            element: <Simulations />,
          },
          {
            path: 'pathfinder',
            element: <Pathfinder />,
          },
          {
            path: 'count-quickly',
            element: <CountQuickly />,
          },
        ],
      },
      {
        path: 'coach',
        element: <CoachLayout />,
        children: [
          {
            path: 'schedule',
            children: [
              {
                path: '',
                element: <Schedule />,
              },
              {
                path: 'new-schedule',
                element: <NewSchedule />,
              },
              {
                path: 'edit-schedule/:id',
                element: <EditSchedule />,
              },
            ],
          },
          {
            path: 'courses',
            children: [
              {
                path: '',
                element: <CoachCoursesMain />,
              },
              {
                path: ':id/group/:groupId',
                element: <CoachCourseDetail />,
              },
              {
                path: ':id/group/:groupId/student/:studentId',
                element: <CoachCourseStudent />,
              },
            ],
          },
          {
            path: 'library',
            children: [
              {
                path: '',
                element: <CoachLibraryList />,
              },
              {
                path: 'create',
                element: <CoachLibraryCreate />,
              },
              {
                path: 'edit/:id',
                element: <div>library edit</div>,
              },
            ],
          },
          {
            path: 'homeworks',
            children: [
              {
                path: '',
                element: <HomeWorks />,
              },
              {
                path: 'homework/:id',
                element: <HomeWorkDetail />,
              },
              {
                path: 'homework/edit/:id',
                element: <EditHomeWork />,
              },
              {
                path: 'new-homework',
                element: <NewHomework />,
              },
              {
                path: 'group/:id',
                element: <HomeWorksGroups />,
              },
              {
                path: 'hometask/:homeworkId',
                element: <CheckHomeWork />,
              },
            ],
          },
          {
            path: 'check-hometask',
            element: <CheckHomeWorkForTest />,
          },
          {
            path: 'lessons',
            children: [
              {
                path: '',
                element: <Lessons />,
              },
              {
                path: 'new-lesson',
                element: <NewLessons />,
              },
              {
                path: 'lesson/:id',
                element: <CoachLessonDetail />,
              },
              {
                path: 'lesson/edit/:id',
                element: <EditLessonDetail />,
              },
              {
                path: 'lesson/attendance/:id',
                element: <LessonAttendance />,
              },
            ],
          },
          {
            path: 'tests',
            children: [
              {
                path: '',
                element: <CoachTests />,
              },
              {
                path: 'create-test',
                element: <TestCreate />,
              },
              {
                path: 'test/:testId',
                element: <TestCoachDetail />,
              },
              {
                path: 'edit/:testId',
                element: <TestEdit />,
              },
            ],
          },
          {
            path: 'simulations',
            children: [
              {
                path: '',
                element: <CoachSimulations />,
              },
              {
                path: 'simulation/:id',
                element: <CoachSimulationDetail />,
              },
              {
                path: 'new-simulation',
                element: <CoachNewSimulation />,
              },
              {
                path: 'edit-simulation/:id',
                element: <CoachEditSimulation />,
              },
            ],
          },
          {
            path: 'journal',
            element: <HRJournalDetail />,
          },
          {
            path: 'edit-profile',
            element: <EditCoucheProfile />,
          },
          {
            path: 'change-password',
            element: <Password />,
          },
          {
            path: 'check-assignments',
            element: <CheckAssignments />,
            children: [
              {
                path: 'homeworks',
                element: <CheckHomeworks />,
                children: [
                  {
                    path: '',
                    element: <HomeworksForCheck />,
                  },
                  {
                    path: 'checked',
                    element: <CheckedHomeworks />,
                  },
                ],
              },
              {
                path: 'tests',
                element: <CheckTests />,
              },
            ],
          },
        ],
      },
      {
        path: 'hr',
        element: <HrLayout />,
        children: [
          {
            path: 'dashboard',
            element: <HrDashboard />,
          },
          {
            path: 'applications',
            element: <HRApplications />,
          },
          {
            path: 'groups',
            children: [
              {
                path: '',
                element: <HrGroups />,
              },
              {
                path: ':id',
                element: <HrGroupDetail />,
              },
              {
                path: 'new',
                element: <HrNewGroup />,
              },
              {
                path: 'edit/:id',
                element: <HrEditGroupDetail />,
              },
            ],
          },
          {
            path: 'users',
            element: <Users />,
            children: [
              {
                path: 'coaches',
                children: [
                  {
                    path: '',
                    element: <CoachList />,
                  },
                  {
                    path: 'new-coach',
                    element: <NewCoach />,
                  },
                ],
              },
              {
                path: 'students',
                children: [
                  {
                    path: '',
                    element: <HrStudents />,
                  },
                  {
                    path: 'new-student',
                    element: <NewStudent />,
                  },
                ],
              },
              {
                path: 'managers',
                children: [
                  {
                    path: '',
                    element: <Managers />,
                  },
                  {
                    path: 'new-manager',
                    element: <NewManager />,
                  },
                ],
              },
            ],
          },
          {
            path: 'coaches/:id',
            element: <HrCoachDetail />,
          },
          {
            path: 'students/:id',
            element: <HrStudentDetail />,
          },
          {
            path: 'managers/:id',
            element: <HrManagerDetail />,
          },
          {
            path: 'training',
            element: <HrTraining />,
          },
          // OLD JOURNAL
          // {
          //   path: 'journal',
          //   element: <HRJournal />,
          // },
          // NEW JOURNAL
          {
            path: 'journal',
            element: <HRJournalDetail />,
          },
          {
            path: 'courses',
            children: [
              {
                path: '',
                element: <HrCourses />,
              },
              {
                path: 'course/:id',
                element: <HrCourseDetail />,
              },
              {
                path: 'new-course',
                element: <NewCourse />,
              },
              {
                path: 'edit/:id',
                element: <HrEditCourseDetail />,
              },
            ],
          },
          {
            path: 'edit-profile',
            element: <EditHrProfile />,
          },
          {
            path: 'change-password',
            element: <HrPassword />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: 'ui',
    element: <UI />,
  },
  // {
  //   path: 'qr',
  //   element: <QR />,
  // },
]);
