import React, { useMemo } from 'react';

import cl from './title.module.scss';

interface TitleProps {
  tag: 'h1' | 'h2' | 'h3' | 'h4';
  className?: string;
  children: React.ReactNode;
}

const LMSTitle: React.FC<TitleProps> = ({ tag, className = '', children }) => {
  const Heading = tag;
  const titleClass = useMemo(
    () => `
      ${cl['title']}
      ${cl[`title__${tag}`]}
      ${className}
    `,
    [tag]
  );
  return (
    <Heading style={{ color: 'black' }} className={titleClass}>
      {children}
    </Heading>
  );
};

export default LMSTitle;
