import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as BookIcon } from 'assets/icons/book.svg';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as FileIcon } from 'assets/icons/file.svg';
import { ReactComponent as ReloadIcon } from 'assets/icons/reload.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import Avatar from 'components/Avatar';
import LMSButton from 'components/LMSButton';
import PDFView from 'components/PDF';

import { fetchCourseLesson, sendAttendanceStatus } from '../../api/repository/my-courses';
import LessonComments from '../../components/LessonComments';
import Loading from '../../components/Loading';
import { ActiveCourse } from '../../entities/ActiveCourse';
import { ISingleLessonFiles, Lesson, SingleLesson } from '../../entities/Lesson';
import { useLessonComments } from '../../hooks/lesson-comments';

const LessonDetail = () => {
  const navigate = useNavigate();
  const { id, lessonId } = useParams();
  const [query, setQuery] = useSearchParams();
  const location = useLocation();
  const { isLoading: isLessonCommentsLoading } = useLessonComments();
  const course = useOutletContext<ActiveCourse>();

  const [isLoading, setLoading] = useState(false);
  const [lesson, setLesson] = useState<SingleLesson | null>(null);

  useEffect(() => {
    if (!lessonId) return;

    setLoading(true);
    fetchCourseLesson(+lessonId)
      .then((res) => {
        setLesson(res);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, [id, lessonId]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  const prevLessonId = useMemo(() => {
    if (!lessonId) return;

    let lessons: Lesson[] = [];
    for (let i = 0; i < course.modules.length; i++) {
      lessons = lessons.concat(course.modules[i].lessons);
    }

    let prevLesson = null;
    for (let i = 0; i < lessons.length; i++) {
      if (lessons[i].id === +lessonId && lessons[i - 1]) {
        prevLesson = lessons[i - 1].id;
      }
    }
    return prevLesson;
  }, [course, lessonId]);

  const changeAttendanceStatus = async () => {
    const groupId = query.get('groupId');
    setLoading(true);
    await sendAttendanceStatus(+lesson!.calendar_id, +groupId!)
      .then(() => {
        setLesson(
          Object.assign({}, lesson, {
            is_present: true,
            is_present_exists: true,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const goToBroadcast = (link: string) => {
    window.open(link, '_blank');
  };

  const navigateToHomework = () => {
    navigate(`/student/active/${course.id}/homeworks/${lessonId}`);
  };

  const getVideoFormat = (videoName: string) => {
    const splitVideoName = videoName.split('.');
    return `video/${splitVideoName[splitVideoName.length - 1]}`;
  };

  const mapFiles = (files: ISingleLessonFiles[]) => {
    return {
      pdf: files.filter((file: ISingleLessonFiles) => file.name.includes('pdf')),
      without: files.filter((file: ISingleLessonFiles) => !file.name.includes('pdf')),
    };
  };

  if (isLoading || isLessonCommentsLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (!lesson) return <div />;

  if (!lesson?.is_accessed) return <div>Урок недоступен</div>;

  return (
    <div>
      <p className="text-3xl" style={{ color: 'black' }}>
        Урок {lesson?.position}: {lesson?.name}
      </p>

      <div
        className={`mt-8 p-10 rounded-3xl theme-student-card w-full overflow-hidden ${
          !lesson?.description &&
          !lesson?.videoUrl &&
          !lesson?.videoYoutubeUrl &&
          !lesson?.videoBroadcastUrl &&
          'hidden'
        }`}
      >
        {lesson?.description && lesson.description.length && (
          <div className="text-xl theme-text--secondary">
            <div
              className="text-current text-lg student-description select-none"
              dangerouslySetInnerHTML={{ __html: lesson?.description }}
            />
          </div>
        )}

        {lesson?.videoUrl ? (
          <div className="flex flex-col space-y-10">
            {lesson?.videoUrl?.map((file, index) => (
              <div key={index} className="w-full">
                <video controls className="rounded-xl overflow-hidden w-full">
                  <source src={file.downloadLink} type={getVideoFormat(file.name)} />
                </video>
              </div>
            ))}
          </div>
        ) : null}

        {lesson?.videoYoutubeUrl && lesson?.videoYoutubeUrl?.startsWith('https') ? (
          <div className="mt-10">
            <p className="mb-10 text-3xl">YouTube</p>

            <div className="aspect-video w-full rounded-xl overflow-hidden">
              <iframe
                className="w-full h-full"
                src={lesson?.videoYoutubeUrl?.replace(
                  'https://youtu.be',
                  'https://youtube.com/embed'
                )}
                title={lesson?.name}
                frameBorder={0}
                allowFullScreen
              />
            </div>
          </div>
        ) : null}

        <div className="flex items-center justify-center space-x-3 mt-10">
          {prevLessonId ? (
            <LMSButton
              prefix={<ReloadIcon />}
              className="theme-background--grey"
              onClick={() => navigate(`/student/active/${course.id}/lessons/${prevLessonId}`)}
            >
              ПРЕДЫДУЩИЙ УРОК
            </LMSButton>
          ) : null}
          {lesson?.videoBroadcastUrl ? (
            <LMSButton
              colorType="light"
              prefix={<CameraIcon />}
              onClick={() =>
                goToBroadcast(lesson.videoBroadcastUrl ? lesson.videoBroadcastUrl : '')
              }
            >
              ПЕРЕЙТИ НА ТРАНСЛЯЦИЮ
            </LMSButton>
          ) : null}
          {!lesson?.is_present_exists && (
            <LMSButton onClick={() => changeAttendanceStatus()}>Ознакомился</LMSButton>
          )}
          {lesson?.homework && lesson?.is_present_exists ? (
            <LMSButton onClick={() => navigateToHomework()} prefix={<BookIcon />}>
              ДОМАШНЕЕ ЗАДАНИЕ
            </LMSButton>
          ) : null}
        </div>
      </div>

      <div
        className={`flex flex-col-reverse mt-8 ${
          // lesson?.fileUrl && 'md:grid grid-cols-[2fr_1fr] gap-5'
          lesson?.fileUrl && 'md:flex flex-col gap-5'
        }`}
      >
        {lesson?.fileUrl && (
          <div>
            <div className="flex items-center space-x-2 text-3xl">
              <p>Материалы</p> <FileIcon />
            </div>

            <div className="flex flex-col space-y-5 mt-5">
              {mapFiles(lesson?.fileUrl).pdf?.map((file) => (
                <div key={file?.downloadLink} className="mb-4 flex justify-center w-full">
                  <PDFView fileLink={file?.downloadLink} />
                </div>
              ))}
              {mapFiles(lesson?.fileUrl).without?.map((file) => (
                <div
                  key={file?.downloadLink}
                  className="flex items-center justify-between px-8 py-6 rounded-xl theme-student-card"
                >
                  <div>
                    <p className="flex flex-col text-xl">
                      {file?.name}
                      <span className="text-current text-opacity-30">
                        {(Number(file.size) / (1024 * 1024)).toFixed(2)} мб
                      </span>
                    </p>
                  </div>
                  <a
                    href={file?.downloadLink}
                    target="_blank"
                    className="flex flex-col items-center space-y-3 theme-text--secondary"
                    rel="noreferrer"
                  >
                    <UploadIcon />
                    <span>Скачать</span>
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}
        <div
          className={`hidden h-fit p-8 rounded-2xl theme-student-card ${
            lesson?.fileUrl && 'mt-14'
          }`}
        >
          <div className="flex items-center space-x-3 w-[130px] theme-background--grey rounded-full">
            <div className="w-10 h-10 border border-solid border-white rounded-full overflow-hidden">
              <Avatar image={lesson?.coach?.avatar} alt={lesson?.coach?.fullName} />
            </div>
            <p>ТРЕНЕР</p>
          </div>
          <p className="mt-6 text-xl">{lesson?.coach?.fullName}</p>
          <p>{lesson?.coach?.positionLevel}</p>
        </div>
      </div>

      <LessonComments id={lesson.calendar_id} />
    </div>
  );
};

export default LessonDetail;
