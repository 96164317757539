import React, { useEffect, useMemo, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import LMSButton from 'components/LMSButton';
import LMSInput from 'components/LMSInput';
import ru from 'date-fns/locale/ru';
import { useOutsideClick } from 'hooks/outside-click';
import { useWindowSize } from 'hooks/window-size';

import { useTrainingBaseFilter } from '../../hooks/training-base-filter';

registerLocale('ru', ru);

const TrainingBaseFilter = () => {
  const device = useWindowSize();
  const { setFilter, onSubmit, onReset } = useTrainingBaseFilter();

  const [isOpen, setOpen] = useState(false);
  const [company, setCompany] = useState<string>('');
  const [project, setProject] = useState<string>('');
  const [position, setPosition] = useState<string>('');
  const [program, setProgram] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | undefined | null>(null);
  const [endDate, setEndDate] = useState<Date | undefined | null>(null);

  const filterRef = useOutsideClick(() => {
    if (device !== 'desktop') {
      setOpen(false);
    }
  });

  const filterClass = useMemo(() => {
    if (device === 'desktop') {
      return 'relative w-full h-fit flex flex-col';
    }

    if (!isOpen) return 'hidden';

    return 'fixed bottom-0 right-0 left-0 top-24 w-full bg-[#3D436B] px-8 py-5 border border-solid border-[#3D436B] rounded-t-[20px] overflow-auto z-10';
  }, [device, isOpen]);

  const onToggleOpen = () => {
    if (device === 'desktop') return;

    if (isOpen) {
      document.body.classList.remove('modal-open');
    } else {
      document.body.classList.add('modal-open');
    }
    setOpen((prev) => !prev);
  };

  const resetFilters = () => {
    setCompany('');
    setProject('');
    setPosition('');
    setProgram('');
    setFullName('');
    setStartDate(null);
    setEndDate(null);
    onToggleOpen();
    onReset();
  };

  const submitFilters = () => {
    onToggleOpen();
    onSubmit();
  };

  useEffect(() => {
    setFilter({
      company: company || null,
      project: project || null,
      position: position || null,
      program: program || null,
      full_name: fullName || null,
      start_date: startDate || null,
      end_date: endDate || null,
    });
  }, [company, project, position, program, fullName, startDate, endDate]);

  return (
    <div>
      <button
        className="flex lg:hidden items-center justify-center w-full mb-6 p-2 space-x-2 border border-white border-opacity-30 rounded-xl"
        onClick={onToggleOpen}
      >
        <FilterIcon />
        <span>Фильтр</span>
      </button>

      <div ref={filterRef} className={filterClass}>
        <div className="w-full flex flex-col gap-2 md:flex-row md:flex-wrap">
          <div className="w-full md:w-fit md:max-w-[290px]">
            <LMSInput
              name="company"
              placeholder="Компания"
              variant="rounded"
              value={company}
              onChange={(e: string) => setCompany(e)}
            />
          </div>
          <div className="w-full md:w-fit md:max-w-[290px]">
            <LMSInput
              name="project"
              placeholder="Проект"
              variant="rounded"
              value={project}
              onChange={(e: string) => setProject(e)}
            />
          </div>
          <div className="w-full md:w-fit md:max-w-[290px]">
            <LMSInput
              name="position"
              placeholder="Должность"
              variant="rounded"
              value={position}
              onChange={(e: string) => setPosition(e)}
            />
          </div>
          <div className="w-full md:w-fit md:max-w-[290px]">
            <LMSInput
              name="program"
              placeholder="Программа"
              variant="rounded"
              value={program}
              onChange={(e: string) => setProgram(e)}
            />
          </div>
          <div className="w-full md:w-fit md:max-w-[290px]">
            <LMSInput
              name="fullName"
              placeholder="ФИО"
              variant="rounded"
              value={fullName}
              onChange={(e: string) => setFullName(e)}
            />
          </div>
          <div className="training-base-datepicker w-full flex flex-row md:w-fit md:max-w-[290px]">
            <DatePicker
              selected={startDate}
              placeholderText="Дата обучения"
              locale="ru"
              showTimeSelect
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="yyyy:MM:dd HH:mm"
              onChange={(date) => setStartDate(date)}
              className="text-black"
            />
            <CalendarIcon className="ml-[-44px] mt-[14px] text-white lg:ml-[-30px]" />
          </div>
          <div className="training-base-datepicker w-full flex flex-row md:w-fit md:max-w-[290px]">
            <DatePicker
              selected={endDate}
              placeholderText="Дата завершения"
              locale="ru"
              showTimeSelect
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="yyyy:MM:dd HH:mm"
              onChange={(date) => setEndDate(date)}
              className="text-black"
            />
            <CalendarIcon className="ml-[-44px] mt-[14px] text-white lg:ml-[-30px]" />
          </div>
        </div>
        <div className="w-full flex flex-col gap-4 mt-4 md:flex-row">
          {device === 'desktop' ? (
            <>
              <button
                type="button"
                className="w-fit flex-row gap-x-2 text-white md:flex"
                onClick={submitFilters}
                style={{ color: 'black' }}
              >
                &#10003; <span className="underline underline-offset-4">Применить</span>
              </button>
              <button
                type="button"
                className="w-fit flex-row gap-x-2 text-white md:flex"
                onClick={resetFilters}
                style={{ color: 'black' }}
              >
                x <span className="underline underline-offset-4">Сбросить фильтры</span>
              </button>
            </>
          ) : (
            <>
              <LMSButton className="w-full mt-5" colorType="light" onClick={submitFilters}>
                Применить
              </LMSButton>
              <LMSButton
                className="w-full"
                variant="text"
                prefix={<CloseIcon className="w-5 h-5" />}
                onClick={resetFilters}
              >
                Сбросить
              </LMSButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrainingBaseFilter;
