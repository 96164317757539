import React from 'react';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import LMSButton from 'components/LMSButton';
import LMSPagination from 'components/LMSPagination';
import TrainingBaseTable from 'modules/hr/components/TrainingBaseTable';
import { useNotification } from 'hooks/notification';
import request from 'api/axios-instance';

import { IJournalStudent } from '../../api/dao/journal.dao';
import JournalBaseFilter from '../../components/JournalBaseFilter';
import { useJournalBase } from '../../hooks/journal-base';

const HRJournalDetail = () => {
  const { isLoading, journalBase, currentPage, lastPage, setCurrentPage, searchParams } =
    useJournalBase();
  const notify = useNotification();

  const downloadPdfFile = () => {
    if (
      searchParams?.course_id &&
      searchParams?.group_id &&
      searchParams?.startDate &&
      searchParams?.endDate
    ) {
      const link = document.createElement('a');
      link.setAttribute('download', '');
      link.setAttribute('target', '_blank');
      link.href = `${request.getUri()}v2/attendance/report-export?course_id=${
        searchParams?.course_id
      }&group_id=${searchParams?.group_id}&date_from=${searchParams?.startDate}&date_to=${
        searchParams?.endDate
      }`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return;
    }
    notify.error('Заполните все поля фильтра!');
  };

  const checkStudentStatus = (student: IJournalStudent) => {
    return !student.is_present_exists
      ? 'Нет отметки'
      : student.is_present
      ? 'Присутствовал'
      : 'Отсутствовал';
  };

  const checkStudentStatusStyles = (student: IJournalStudent) => {
    const color = !student.is_present_exists
      ? 'text-current'
      : student.is_present
      ? 'text-[#00B341]'
      : 'text-[#FF0000]';
    return `${color} text-end width-full flex-1`;
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex-1">
        <div className="w-full flex flex-row justify-between items-center mb-5">
          <h2 className="text-[32px] font-semibold" style={{ color: 'black' }}>
            Журнал посещаемости
          </h2>

          {!!journalBase?.students.length && !!journalBase?.lessons.length && !isLoading && (
            <LMSButton prefix={<UploadIcon />} onClick={downloadPdfFile}>
              Скачать Excel
            </LMSButton>
          )}
        </div>

        <div className="mb-6">
          <JournalBaseFilter />
        </div>

        {!!journalBase?.students.length && !!journalBase?.lessons.length && !isLoading && (
          <>
            <div className="overflow-x-auto flex flex-col gap-y-4 bg-studentCardBg p-4 rounded-md">
              {journalBase?.lessons.map((lesson) => (
                <div key={lesson.id} className="flex flex-col gap-y-4">
                  <div>
                    <span className="text-xl font-medium">Урок - {lesson.name}</span>
                  </div>

                  {journalBase?.students
                    .filter((student) => student.lesson_id === lesson.id)
                    .map((student, index) => (
                      <aside
                        key={student.student_id}
                        className="w-full flex justify-between items-center"
                      >
                        <div className="flex gap-x-4 items-center max-w-[400px] w-full">
                          <span>{index + 1}</span>
                          <img
                            src={student.avatar}
                            alt={`student ${student.fio} avatar`}
                            className="w-[60px] h-[60px] rounded-full object-cover"
                          />
                          <span>{student.fio}</span>
                        </div>
                        <div className="flex w-full items-center justify-between gap-x-2">
                          <span>{student.lesson_date}</span>
                          <span className={checkStudentStatusStyles(student)}>
                            {checkStudentStatus(student)}
                          </span>
                        </div>
                      </aside>
                    ))}
                </div>
              ))}
            </div>
            {/* <div className={lastPage > 1 ? 'mt-6' : 'hidden'}>
              <LMSPagination current={currentPage} lastPage={lastPage} onChange={setCurrentPage} />
            </div> */}
          </>
        )}

        {journalBase?.students.length === 0 && !isLoading && (
          <h3 className="mb-10 text-[24px] font-semibold text-center mt-10">
            Нет данных <br /> Попробуйте изменить фильтрацию
          </h3>
        )}
      </div>
    </div>
  );
};

export default HRJournalDetail;
