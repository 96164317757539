import { useEffect, useMemo, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import LMSButton from 'components/LMSButton';
import ru from 'date-fns/locale/ru';
import DropdownSelect from 'modules/coach/components/DropdownSelect';
import { useCalendarFilter } from 'modules/coach/hooks/useCalendarFilter';
import { useHandbooks } from 'modules/coach/hooks/useHandbooks';
import { useOutsideClick } from 'hooks/outside-click';
import { useWindowSize } from 'hooks/window-size';

registerLocale('ru', ru);

const defaultSelectValue = {
  id: null,
  name: '',
};

type StatusType = 'active' | 'finished';

const Filter = () => {
  const device = useWindowSize();
  const [query] = useSearchParams();
  const { setFilter, onReset, onSubmit } = useCalendarFilter();
  const { calendarCourses, calendarGroups, onFetchCalendarCourses, onFetchCalendarGroups } =
    useHandbooks();

  const status = query.get('status') as StatusType;

  const [isOpen, setOpen] = useState(false);
  const [course, setCourse] = useState<{ id: number | null; name: string }>(defaultSelectValue);
  const [group, setGroup] = useState<{ id: number | null; name: string }>(defaultSelectValue);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [dateFrom, dateTo] = dateRange;

  const filterRef = useOutsideClick(() => {
    setOpen(false);
  });

  const handleCourseChange = (id: number, name: string) => {
    setCourse({ id, name });
    if (course?.id) {
      setGroup(defaultSelectValue);
      onFetchCalendarGroups({ course_id: course.id });
    }
  };

  const handleGroupChange = (id: number, name: string) => {
    setGroup({ id, name });
    if (group?.id) {
      setCourse(defaultSelectValue);
      onFetchCalendarCourses({ group_id: group.id });
    }
  };

  const filterClass = useMemo(() => {
    if (device === 'desktop') {
      return 'relative w-full h-fit flex flex-col';
    }

    if (!isOpen) return 'hidden';

    return 'fixed bottom-0 right-0 left-0 top-24 w-full bg-[#3D436B] px-8 py-5 border border-solid border-[#3D436B] rounded-t-[20px] overflow-auto z-10';
  }, [device, isOpen]);

  const onToggleOpen = () => {
    if (isOpen) {
      document.body.classList.remove('modal-open');
    } else {
      document.body.classList.add('modal-open');
    }
    setOpen((prev) => !prev);
  };

  const resetFilters = () => {
    setCourse(defaultSelectValue);
    setGroup(defaultSelectValue);
    setDateRange([null, null]);
    onToggleOpen();
    onReset();
  };

  const submitFilters = () => {
    onToggleOpen();
    onSubmit();
  };

  useEffect(() => {
    setFilter({
      course: course.id || null,
      group: group.id || null,
      dateFrom: dateRange[0],
      dateTo: dateRange[1],
    });
  }, [course, group, dateRange]);

  useEffect(() => {
    onFetchCalendarCourses();
    onFetchCalendarGroups();
  }, []);

  return (
    <div>
      <button
        className="flex lg:hidden items-center justify-center w-full p-2 space-x-2 border text-current border-white border-opacity-30 rounded-xl"
        onClick={onToggleOpen}
      >
        <FilterIcon />
        <span>Фильтр</span>
      </button>

      <div ref={filterRef} className={filterClass}>
        <div className="w-full flex flex-col gap-2 md:flex-row md:flex-wrap">
          <div className="w-full md:w-fit md:max-w-[290px]">
            <DropdownSelect
              notRounded={false}
              title={'Выбрать курс'}
              value={course.name}
              list={calendarCourses.map(({ value, label }) => ({ id: value, name: label }))}
              onCourseClick={handleCourseChange}
            />
          </div>
          <div className="w-full md:w-fit md:max-w-[290px]">
            <DropdownSelect
              notRounded={false}
              title={'Выбрать группу'}
              value={group.name}
              list={calendarGroups.map(({ value, label }) => ({ id: value, name: label }))}
              onCourseClick={handleGroupChange}
            />
          </div>
          <div className="training-base-datepicker w-full flex flex-row md:w-fit md:min-w-[240px] md:max-w-[320px]">
            <DatePicker
              selectsRange={true}
              startDate={dateFrom}
              endDate={dateTo}
              placeholderText="Период"
              locale="ru"
              onChange={(date) => setDateRange(date)}
              dateFormat="dd.MM.yyyy"
              className="text-black"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              minDate={status === 'active' ? new Date() : null}
              maxDate={status === 'finished' ? new Date() : null}
            />
            <CalendarIcon className="ml-[-44px] mt-[14px] text-current lg:ml-[-30px]" />
          </div>
        </div>
        <div className="w-full flex flex-col gap-4 mt-4 md:flex-row">
          {device === 'desktop' ? (
            <>
              <button
                type="button"
                className="w-fit flex-row gap-x-2 text-current md:flex"
                onClick={submitFilters}
                style={{ color: 'black' }}
              >
                &#10003;
                <span className="underline underline-offset-4">Применить</span>
              </button>
              <button
                type="button"
                className="w-fit flex-row gap-x-2 text-current md:flex"
                onClick={resetFilters}
                style={{ color: 'black' }}
              >
                x <span className="underline underline-offset-4">Сбросить фильтры</span>
              </button>
            </>
          ) : (
            <>
              <LMSButton className="w-full mt-5" colorType="light" onClick={submitFilters}>
                Применить
              </LMSButton>
              <LMSButton
                className="w-full"
                variant="text"
                prefix={<CloseIcon className="w-5 h-5" />}
                onClick={resetFilters}
              >
                Сбросить
              </LMSButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Filter;
