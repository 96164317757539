import React, { useState } from 'react';

export const CoachLibraryCreate = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [files, setFiles] = useState<FileList | null>(null);
  const [videos, setVideos] = useState<FileList | null>(null);
  const [videoLinks, setVideoLinks] = useState(['']);

  const handleSave = () => {
    // Save logic here
    alert('Material saved! Redirecting to Library...');
    // Redirect logic can go here
  };

  return (
    <div className="pt-6 px-6 max-w-4xl mx-auto">
      <div className="block lg:flex items-start">
        <div className="flex-1">
          <div className="w-full flex flex-row justify-between lg:items-center mb-6 lg:mb-10">
            <h2 className="text-2xl lg:text-[32px] font-semibold">Создать материал</h2>
          </div>
          <form className="space-y-6 flex flex-col p-7 theme-student-card rounded-xl">
            <div>
              <label className="block text-lg font-medium mb-1">Название материала</label>
              <input
                type="text"
                maxLength={100}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none border-white"
                placeholder="Введите название (до 100 символов)"
              />
            </div>
            <div>
              <label className="block text-lg font-medium mb-1">Обложка</label>
              <input
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={(e) => {
                  if (e.target.files) {
                    setCoverImage(e.target.files[0]);
                  }
                }}
                className="w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none border-white"
              />
              <p className="text-sm mt-1">
                Рекомендуемый формат JPEG/PNG, минимальный размер – 300x300px
              </p>
            </div>
            <div>
              <label className="block text-lg font-medium mb-1">Описание</label>
              <textarea
                maxLength={500}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full  py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none border-white"
                placeholder="Введите краткое описание (до 500 символов)"
                rows={4}
              />
            </div>
            <div>
              <label className="block text-lg font-medium mb-1">Загрузить файл</label>
              <input
                type="file"
                multiple
                accept=".pdf,.jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.fb2"
                onChange={(e) => {
                  setFiles(e.target.files);
                }}
                className="w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none border-white"
              />
              <p className="text-sm mt-1">
                Поддерживаемые форматы: PDF, JPEG, Word, Excel, FB2. До 50 МБ.
              </p>
            </div>
            <div>
              <label className="block text-lg font-medium mb-1">Загрузить видео</label>
              <input
                type="file"
                multiple
                accept=".mp4,.avi"
                onChange={(e) => setVideos(e.target.files)}
                className="w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none border-white"
              />
              <p className="text-sm mt-1">Форматы MP4, AVI. До 1 ГБ на каждый файл.</p>
            </div>
            <div>
              <label className="block text-lg font-medium mb-1">Ссылка на видео</label>
              {videoLinks.map((link, index) => (
                <input
                  key={index}
                  type="url"
                  value={link}
                  onChange={(e) => {
                    const newLinks = [...videoLinks];
                    newLinks[index] = e.target.value;
                    setVideoLinks(newLinks);
                  }}
                  className="w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none border-white"
                  placeholder="Введите URL (например, YouTube или Vimeo)"
                />
              ))}
              <button
                type="button"
                onClick={() => setVideoLinks([...videoLinks, ''])}
                className="text-blue-500 hover:text-blue-700 text-sm"
              >
                + Добавить еще ссылку
              </button>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={handleSave}
                className="px-4 py-1 theme-text--default cursor-pointer md:bg-white md:bg-opacity-30 md:rounded-full"
              >
                Сохранить
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
